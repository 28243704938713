.FileButton{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding:1em;
}

video{
    border-radius: 1em;
    border-width: 1em;
    max-width: 60vw;
    max-height: 30vh;
}
.newOldSizeDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width:100%;
}

.FileForm{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100vw;
    align-content: center;
}
.UserUploadFileName{
    padding: 1em;
}
.ProgressDiv{
    padding:0;
    padding-bottom: .2em;
}



.FileInput{
    background-color: #e7e7e7; 
    color: black;    
    border: none;
    color: white;
    padding: 1% 2%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
}

input[type="file"] {
    display: none;
}
button{
    display:none
}
label{
    background-color: #e7e7e7; 
    box-shadow: .1em .1em .1em .1em rgba(0, 0, 0, 0.2);

    color: black;    
    border: none;
    color: black;
    padding: 1% 2%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
}
label:hover{
    cursor: pointer;
}

.DownloadButton{
    background-color: #e7e7e7;
    box-shadow: .1em .1em .1em .1em rgba(0, 0, 0, 0.2);
    color: black;    
    border: none;
    color: black;
    padding: 1% 2%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
}