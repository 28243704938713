.App {
  text-align: center;
  background-size: cover;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow-x: hidden;
}

.Description{
  padding:0;
  margin-top:0;
}



.ButtonHolder{
  border-style: solid;
  border-color: white;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

}
.ConvertMoreButton{
  margin-top: 1%;
  margin-bottom:1%;
}